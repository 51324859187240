<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Imágenes MYT
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Revisa los resultados 🦴
        </b-card-title>
        <b-card-text class="mb-2">
          Ingresa los datos para revisar los resultados
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- numero de documento -->
            <b-form-group
              label-for="email"
              label="Documento"
            >
              <validation-provider
                #default="{ errors }"
                name="documento"
                rules="required|integer"
              >
                <b-form-input
                  id="email"
                  v-model="documento"
                  name="documento"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Ingresa el numero de documento"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="v-first-name">codigo del paciente</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>¿Olvidaste el código?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="codigo"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="codigo del paciente"
                    v-model="codigo"

                    :state="errors.length > 0 ? false:null"
                    name="codigo"
                    placeholder="codigo"
                  />

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validarPaciente(documento,codigo)"
            >
              Revisar
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'Home'}">
            <feather-icon icon="ChevronLeftIcon" /> Regresar
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            MYT
          </div>
        </div>

        <!-- social button -->

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      documento: '',
      password: '',
      status: '',
      codigo: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validarPaciente(document, codigoResultado) {
      this.usuarioDocumento = {
        documento: document,
        codigo: codigoResultado,
        rol: 'Paciente',
      }
      this.$store.dispatch('apiFast/validarUsuario', this.usuarioDocumento).then(response => {
        // console.log('datos correooooo')
        this.validar = response.data
        // console.log(response)
        // console.log(this.validar)
        if (this.validar) this.$router.push({ name: 'historial' })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
